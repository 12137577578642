const showAlert = (title, icon ) => {
    const toast = window.Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        padding: '.8em',
        timerProgressBar: true,
    });

    toast.fire({
        icon: icon,
        title: title,
    });
};

export default showAlert;
import Swal from 'sweetalert2';

const showToast = (icon, title) => {
    const toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 5000,
        padding: '.8em',
        timerProgressBar: true,
    });

    toast.fire({ icon, title });
};

const handleApiResponse = (error, showMessage = false) => {
    // console.log(error.response.data);

    if (error.response?.status === 401) {
        showToast('error', 'Sessão expirada. Seu token de login expirou. Você será redirecionado para a página de login.');

        setTimeout(() => {
            localStorage.clear();
            window.location.reload();
        }, 5000);
        return;
    }

    if (error.code == 'ERR_NETWORK') {
        showToast('error', 'Desculpe-nos, mas estamos enfrentando problemas com a conexão no momento. Por favor, tente novamente mais tarde.');
        return;
    }

    if (error.response?.data && showMessage) {
        if (error.response.data.menssagem) {
            showToast('error', error.response.data.menssagem);
        } else {
            showToast('error', 'Ocorreu um erro!');
        }
    }

    if (error.data && showMessage) {
        if (error.data.sucesso) {
            showToast('success', error.data.menssagem);
        } else {
            showToast('warning', error.data.menssagem);
        }
    }
};

export default handleApiResponse;

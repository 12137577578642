<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                <li class="menu">
                    <router-link to="/" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-home"
                            >
                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            <span>{{ $t('dashboard') }} </span>
                        </div>
                    </router-link>
                </li>

                <li class="menu" v-if="role.includes('Master')">
                    <router-link to="/master" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-activity"
                            >
                                <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
                            </svg>
                            <span>{{ $t('dashboard bi') }} </span>
                        </div>
                    </router-link>
                </li>

                <li class="menu" v-if="!role.includes('Admin') && !responsaveisPorSetor.includes(role)">
                    <router-link to="/atividades" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-clipboard"
                            >
                                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                                <rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
                            </svg>
                            <span>{{ $t('activities') }}</span>
                        </div>
                    </router-link>
                </li>

                <li class="menu" v-if="role.includes('Admin') || responsaveisPorSetor.includes(role)">
                    <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#activities" aria-controls="activities" aria-expanded="false">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-clipboard"
                            >
                                <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                                <rect x="8" y="2" width="8" height="4" rx="1" ry="1" />
                            </svg>
                            <span>{{ $t('activities') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id="activities" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li>
                            <router-link to="/atividades" @click="toggleMobileMenu">
                                {{ $t('time clock') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/atividades/gerenciar" @click="toggleMobileMenu">
                                {{ $t('management') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/atividades/lancamentos" @click="toggleMobileMenu" v-if="role.includes('Admin')">
                                {{ $t('record an activity') }}
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="menu">
                    <router-link to="/atividades/historico" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-clock"
                            >
                                <circle cx="12" cy="12" r="10" />
                                <polyline points="12 6 12 12 16 14" />
                            </svg>
                            <span>{{ $t('history') }}</span>
                        </div>
                    </router-link>
                </li>

                <li class="menu" v-if="role.includes('Admin')">
                    <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#client" aria-controls="client" aria-expanded="false">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-smile"
                            >
                                <circle cx="12" cy="12" r="10" />
                                <path d="M8 14s1.5 2 4 2 4-2 4-2" />
                                <line x1="9" y1="9" x2="9.01" y2="9" />
                                <line x1="15" y1="9" x2="15.01" y2="9" />
                            </svg>
                            <span>{{ $t('client') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id="client" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li>
                            <router-link to="/clientes/listar" @click="toggleMobileMenu">
                                {{ $t('display') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/clientes/Cadastrar" @click="toggleMobileMenu">
                                {{ $t('register') }}
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="menu" v-if="role.includes('Admin')">
                    <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#user" aria-controls="user" aria-expanded="false">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-users"
                            >
                                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
                                <circle cx="9" cy="7" r="4" />
                                <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                            </svg>
                            <span>{{ $t('users') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id="user" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li>
                            <router-link to="/usuarios/listar" @click="toggleMobileMenu">
                                {{ $t('display') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/usuarios/cadastrar" @click="toggleMobileMenu">
                                {{ $t('register') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/usuarios/cargos" @click="toggleMobileMenu">
                                {{ $t('roles') }}
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="menu" v-if="role.includes('Admin')">
                    <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#report" aria-controls="report" aria-expanded="false">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-table"
                            >
                                <path d="M9 3H5a2 2 0 0 0-2 2v4m6-6h10a2 2 0 0 1 2 2v4M9 3v18m0 0h10a2 2 0 0 0 2-2V9M9 21H5a2 2 0 0 1-2-2V9m0 0h18" />
                            </svg>
                            <span>{{ $t('report') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id="report" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li>
                            <router-link to="/relatorios/historico-atividade" @click="toggleMobileMenu">
                                {{ $t('activity History') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/relatorios/historico-dia-dia" @click="toggleMobileMenu">
                                {{ $t('day-to-Day History') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/relatorios/horas-por-clientes" @click="toggleMobileMenu">
                                {{ $t('hours by Client') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/relatorios/Relatorio-De-wCusto-Por-Cliente" @click="toggleMobileMenu" v-if="role.includes('Master')">
                                {{ $t('cost per client') }}
                            </router-link>
                        </li>
                    </ul>
                </li>
                <li class="menu" v-if="role.includes('Admin') || responsaveisPorSetor.includes(role)">
                    <a class="dropdown-toggle" data-bs-toggle="collapse" data-bs-target="#os" aria-controls="os" aria-expanded="false">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-airplay"
                            >
                                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1" />
                                <polygon points="12 15 17 21 7 21 12 15" />
                            </svg>
                            <span>{{ $t('service order') }}</span>
                        </div>
                        <div>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-right"
                            >
                                <polyline points="9 18 15 12 9 6"></polyline>
                            </svg>
                        </div>
                    </a>

                    <ul id="os" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
                        <li>
                            <router-link to="/ordem-de-servico" @click="toggleMobileMenu">
                                {{ $t('os') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/ordem-de-servico-ti" @click="toggleMobileMenu">
                                {{ $t('Chamados TI') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/ordem-de-servico-listar" @click="toggleMobileMenu">
                                {{ $t('manage os') }}
                            </router-link>
                        </li>
                    </ul>
                </li>

                <li class="menu" v-if="!role.includes('Admin') && !responsaveisPorSetor.includes(role)">
                    <router-link to="/ordem-de-servico" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-airplay"
                            >
                                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1" />
                                <polygon points="12 15 17 21 7 21 12 15" />
                            </svg>
                            {{ $t('service order') }}
                        </div>
                    </router-link>
                </li>

                <li class="menu">
                    <router-link to="/perfil" class="dropdown-toggle" @click="toggleMobileMenu">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-user"
                            >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                <circle cx="12" cy="7" r="4" />
                            </svg>
                            {{ $t('profile') }}
                        </div>
                    </router-link>
                </li>

                <li class="menu">
                    <a href="javascript:void(0);" class="dropdown-toggle" @click="toggleMobileMenu" data-bs-toggle="modal" data-bs-target="#modalSupport">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-code"
                            >
                                <polyline points="16 18 22 12 16 6" />
                                <polyline points="8 6 2 12 8 18" />
                            </svg>
                            {{ $t('support') }}
                        </div>
                    </a>
                </li>

                <li class="menu">
                    <a href="javascript:void(0);" class="dropdown-toggle" @click="logout">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-log-out"
                            >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                            {{ $t('exit') }}
                        </div>
                    </a>
                </li>
            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
    <div class="modal fade" id="modalSupport" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('support') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="modalOption === 'bug'">
                        <form @submit.prevent="postBug">
                            <h4>{{ $t('report bug or inconsistency') }}</h4>
                            <div class="row mb-5">
                                <div class="col-lg-4 col-12">
                                    <label for="nomeUsuario" class="form-label">{{ $t('user') }}</label>
                                    <input id="nomeUsuario" class="form-control" type="text" disabled v-model="supportObject.nome" />
                                </div>
                                <div class="col-lg-4 col-12">
                                    <label for="setorUsuario" class="form-label">{{ $t('sector') }}</label>
                                    <input id="setorUsuario" class="form-control" type="text" disabled v-model="supportObject.setor" />
                                </div>
                                <div class="col-lg-4 col-12">
                                    <label for="localChamado" class="form-label">{{ $t('location of the call') }}</label>
                                    <input id="localChamado" class="form-control" type="text" disabled v-model="supportObject.localDoChamado" />
                                </div>
                            </div>

                            <div class="mb-3">
                                <textarea
                                    required
                                    class="form-control"
                                    minlength="10"
                                    maxlength="2000"
                                    rows="4"
                                    :placeholder="$t('describe the problem encountered')"
                                    v-model="supportObject.descricao"
                                ></textarea>
                                <small> {{ tamanhoDotexto(supportObject.descricao) }} / 2000</small>
                            </div>

                            <div class="row">
                                <div class="mb-5 col-lg-6 col-12">
                                    <label
                                        ><small>{{ $t('optional') }}</small></label
                                    >
                                    <input class="form-control" type="file" @change="(event) => handleFileUpload(event)" />
                                </div>
                                <div class="mb-5 col-lg-6 col-12">
                                    <label>{{ $t('urgency') }}</label>
                                    <select class="form-control" v-model="supportObject.urgenciaId">
                                        <option v-for="urgencia in urgencias" :key="id" :value="urgencia.id">{{ urgencia.nome }}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="text-center">
                                <button type="submit" class="btn btn-primary fs-5 w-50">{{ $t('submit') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" id="btnModalSupport" class="btn btn-dark" data-bs-dismiss="modal">{{ $t('close') }}</button>
                </div>
            </div>
        </div>
    </div>
    <loaderComponent v-if="loader" />
</template>

<script setup>
    import { onMounted, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import decodeJWT from '@/composables/decodeJWT';
    import notifyImg from '@/assets/images/logo/logo.png';
    import apiClient from '@/api';
    import enumSetores from '@/composables/enum/enumSetores';
    import showAlert from '@/composables/showAlert';
    import loaderComponent from '../loaderComponent.vue';
    import handleApiResponse from '@/composables/handleApiResponse';
    const menu_collapse = ref('dashboard');

    const store = useStore();
    const route = useRoute();
    const i18n = useI18n();
    const selectedLang = ref(null);
    const countryList = ref(store.state.countryList);
    const role = ref('');
    const responsaveisPorSetor = ref([
        'ResponsavelFinanceiro',
        'ResponsavelFiscal',
        'ResponsavelContabil',
        'ResponsavelDepartamento',
        'ResponsavelTi',
        'ResponsavelAuditoria',
        'ResponsavelAdministrativo',
        'ResponsavelDepartamento',
        'ResponsavelMarketing',
        'ResponsavelComercial',
        'ResponsavelProcessos',
        'ResponsavelSocietarios',
    ]);
    const modalOption = ref('bug');
    const usuario = ref(decodeJWT());
    const notifyState = ref(null);
    const setores = enumSetores();
    const urgencias = ref({});

    let loader = ref(false);

    /** Objeto do support
     */
    const supportObject = ref({
        usuarioId: decodeJWT()['userId'],
        nome: decodeJWT()['Nome'],
        setor: setores.getSector(decodeJWT()['setor']),
        localDoChamado: route.fullPath,
        descricao: null,
        urgenciaId: 1,
        imagemBase64: null,
    });
    /** Get das Urgencias do banco de dados
     */
    const getUrgencias = async () => {
        try {
            const response = await apiClient.get(`OrdemDeServico/GetUrgencias`);
            urgencias.value = response.data.objeto;
            handleApiResponse(response);
        } catch (error) {
            handleApiResponse(error, true);
        }
    };

    const logout = () => {
        localStorage.clear();
        window.location.reload();
    };

    const notifyPermission = async () => {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
        } else {
            notifyState.value = Notification.permission;
            new Notification('Notificação Ativa', {
                body: 'As Notificações do TimeSheet estão Ativas !',
                icon: notifyImg,
            });
        }
    };

    const postBug = async () => {
        loader.value = true;
        let objetoBug = { ...supportObject.value };
        objetoBug.setor = parseInt(decodeJWT()['setor']);

        try {
            const response = await apiClient.post(`Suporte?usuarioCadastrouId=${decodeJWT()['userId']}`, objetoBug);

            if (response.status === 201) {
                loader.value = false;
                Swal.fire({
                    icon: 'success',
                    title: 'Chamado aberto!',
                    text: 'O chamado foi aberto com sucesso.',
                    confirmButtonText: 'OK',
                }).then(() => {
                    const modalBtn = document.querySelector('#btnModalSupport');
                    if (modalBtn) {
                        modalBtn.click();
                    } else {
                        // console.error("Botão com ID 'btnModalSupport' não foi encontrado.");
                    }
                });
            } else {
                showAlert('Erro, verifique o Formulário!', 'error');
            }
        } catch (error) {
            showAlert('Erro, verifique o Formulário!', 'error');
        }
        loader.value = false;
    };

    const tamanhoDotexto = (text) => {
        if (text) {
            return text.length;
        } else {
            return 0;
        }
    };

    watch(
        () => route.fullPath,
        (newRoute) => {
            supportObject.value.localDoChamado = newRoute;
        }
    );

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = () => {
                const base64String = reader.result.split(',')[1];
                supportObject.value.imagemBase64 = base64String;
            };

            reader.onerror = (error) => {
                // console.error('Erro ao converter o arquivo para base64:', error);
            };

            reader.readAsDataURL(file);
        }
    };

    // ------------
    onMounted(() => {
        const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
        if (selector) {
            const ul = selector.closest('ul.collapse');
            if (ul) {
                let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
                if (ele) {
                    ele = ele[0];
                    setTimeout(() => {
                        ele.click();
                    });
                }
            } else {
                selector.click();
            }
        }
        if (localStorage.getItem('token') && decodeJWT()['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']) {
            role.value = decodeJWT()['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        }
    });

    const toggleMobileMenu = () => {
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };
</script>

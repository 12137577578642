<template>
    <div class="layout-px-spacing dash_1">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item active" aria-current="page"><span>Home</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>

            
        </teleport>

        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
                <div class="widget widget-revenue text-white p-4">
                    <h1 class="fs-1">
                        {{ $t('welcome') }}, <span class="text-primary">{{ decodeJWT()['Nome'] }}</span
                        >!
                    </h1>
                    <p class="fs-4">
                        {{ $t('welcome message') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useMeta } from '@/composables/use-meta';
    useMeta({ title: 'Home Funcionário' });
    
    import '@/assets/sass/widgets/widgets.scss';
    import decodeJWT from '@/composables/decodeJWT';
</script>

<template>
    <div class="loader__box w-100 h-100 d-flex justify-content-center align-items-md-center">
        <div class="loader"></div>
    </div>
</template>

<style scoped>

.loader__box{
background-color: rgba(0, 0, 0, 0.478);
position: fixed;
top: 0;
left: 0;
z-index: 9999;
}

</style>